.LessonNoAccess {
  display: flex;
  justify-content: center;
}

.LessonNoAccess > div {
  border: 1px solid var(--color-subtle-medium);
  border-radius: var(--border-radius);
  padding: 3rem 2em;
  width: 66%;
  font-size: var(--font-size-sm);
  text-align: center;
  color: var(--color-front);
  background-color: var(--color-back);
}
